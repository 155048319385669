
import Vue from "vue";

interface Milestone {
  description: string;
  type: string;
  start_date: string;
  duration: string;
  duration_type: "days" | "hours" | "weeks";
  file_type: "url" | "file";
  file: string | Blob;
}

export default Vue.extend({
  name: "AddMilestoneModal",
  props: {
    milestone: {
      type: Object
    },
    milestoneTypes: {
      type: Object
    },
    isEditMode: {
      type: Boolean
    }
  },
  data() {
    return {
      maxDescriptionChars: 200,
      durationOptions: ["weeks", "days", "hours"],
      milestoneType: null,
      durationOption: "",
      file_url: "" as string,
      file_obj: "" as string | Blob,
      data: {
        description: "",
        type: "",
        start_date: "",
        duration: "1",
        duration_type: "days",
        file_type: "url",
        file: "" as string
      } as Milestone,
      btnLoading: false,
      errors: {} as any,
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  watch: {
    isEditMode: function(val) {
      if (val) {
        this.data = { ...this.milestone };

        if (this.milestone && this.milestone.file_type === "url")
          this.file_url = this.milestone.file;
        this.data.start_date = this.milestone.start_date
          .split("-")
          .reverse()
          .join("-");
      } else {
        this.data = {
          description: "",
          type: "",
          start_date: "",
          duration: "",
          duration_type: "days",
          file_type: "url",
          file: ""
        };
      }
    }
  },
  methods: {
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    selectDate(date: string) {
      this.data.start_date = date
        .split("-")
        .reverse()
        .join("-");
    },
    updateOrCreate() {
      this.btnLoading = true;
      this.$store
        .dispatch("milestone/UPDATE_OR_CREATE_MILESTONE", {
          programId: this.$route.params.id,
          data: this.buildData(),
          milestoneId: this.milestone?.id || null
        })
        .then(() => {
          this.btnLoading = false;
          const type = this.isEditMode ? "updated" : "created";
          (this as any).$alertify.success(`Milestone ${type} successfully`);
          this.$bvModal.hide("add-milestone");
          this.$emit("refresh");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    handleFileUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0] as File | undefined;
      if (file) {
        try {
          this.file_obj = file;
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    buildData(): FormData {
      const formData = new FormData();

      if (this.data.file_type === "url") {
        this.data.file = this.file_url;
      } else {
        this.data.file = this.file_obj;
      }

      for (const key in this.data) {
        const value = this.data[key as keyof Milestone];
        if (value !== null) {
          formData.append(key, value as string | Blob);
        } else {
          //@ts-ignore
          formData.append(key, "");
        }
      }

      if (this.milestone) {
        formData.append("_method", "PUT");
      }
      return formData;
    }
  }
});
